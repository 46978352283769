<template>
  <b-row>
    <b-col v-show="documentRepliesid" md="12">

      <b-card title="مسار الكتاب" class="text-left">
        <b-card-text>
          يوضح هذه الجزء من الصفحة سير الكتاب بين الاقسام الادارية ذات الصلة
        </b-card-text>
        <app-timeline>
          <app-timeline-item v-for="reply in replies" :key="reply.id" icon="UserIcon">
            <div class="
                    d-flex
                    flex-sm-row flex-column flex-wrap
                    justify-content-between
                    mb-1 mb-sm-0
                  ">
              <h6>{{ reply.user.full_name }}</h6>
              <div>
                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="info" class="btn-icon rounded-circle"
                  v-b-modal.model-r-w>
                  <feather-icon icon="CornerDownRightIcon" />
                </b-button>
                <small style="margin-right:10px;margin-left:10px;"
                  class="text-muted">{{ shortenDate(reply.created_at) }}</small>
              </div>
            </div>
            <p>{{ reply.message }} </p>
            <p v-if="reply.mentions.length > 0">{{ mentions(reply.mentions) }}</p>
            <p>
              <a v-if="reply.file != null && reply.file != 'null'"
                :href="'https://www.nuc.edu.iq/newbackend/public/' + reply.file">
                <b-img :src="require('@/assets/images/icons/pdf.png')" height="auto" width="20" class="mr-1" />
                <span class="align-bottom">الكتاب الرسمي</span></a>
            </p>
          </app-timeline-item>
        </app-timeline>
        <div class="demo-inline-spacing">
          <b-button @click="hideCard()" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
            <feather-icon icon="Minimize2Icon" class="mr-50" />
            <span class="align-middle">اغلاق</span>
          </b-button>
          <b-button @click="printProgress()" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
            <feather-icon icon="ArchiveIcon" class="mr-50" />
            <span class="align-middle">طباعة</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-b-modal.model-r-w
            >
            <feather-icon
            icon="ArchiveIcon"
            class="mr-50"
            />
              <span class="align-middle">رد على الطلب</span> 
            </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col v-show="documentform" md="12">

      <b-card title="استمارة المعلومات" class="text-center">
        <!-- <b-card-text>
            معلومات المتقدم على المنحة
          </b-card-text> -->
        <b-form-row>
          <b-col md="12">
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="input-valid1">اسم الطالب :</label>
              <b-form-input id="f_name" v-model="cform.title" placeholder="" disabled />
            </b-form-group>

          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="input-valid1">القسم العلمي :</label>
              <b-form-input id="f_name" v-model="cform.depart" placeholder="" disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <label for="f_name"> المرحلة الدراسية :</label>
            <b-form-input id="f_name" v-model="cform.stage" placeholder="" disabled />
          </b-col>
          <b-col md="4">
            <label for="m_name">نوع الدراسة :</label>
            <b-form-input id="m_name" v-model="cform.type_id" placeholder="" disabled />
          </b-col>
          <b-col md="4">
            <label for="g_name">عنوان السكن :</label>
            <b-form-input id="g_name" v-model="cform.address" placeholder="" disabled />
          </b-col>
          <b-col md="4">
            <label for="g_name">فصيلة الدم :</label>
            <b-form-input id="g_name" v-model="cform.blood" placeholder="" disabled />
          </b-col>
          <b-col md="4">
            <label for="f_m_name">رقم الموبايل</code> :</label>
            <b-form-input id="f_m_name" v-model="cform.phone" placeholder="" disabled />
          </b-col>
          <b-col md="4">
            <label for="input-valid1">الايميل :</label>
            <b-form-input id="input-valid1" v-model="cform.email" placeholder="" disabled />
          </b-col>

        </b-form-row>



        <div class="demo-inline-spacing">
          <b-button @click="hideform()" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
            <feather-icon icon="Minimize2Icon" class="mr-50" />
            <span class="align-middle">اغلاق</span>
          </b-button>
          <b-button @click="printProgress()" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
            <feather-icon icon="ArchiveIcon" class="mr-50" />
            <span class="align-middle">طباعة</span>
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col md="12" class="table">
      <b-card-code title="طلبات اصدار الهوية" no-body>
        <b-card-body v-if="!$route.params.id">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- filter -->
            <b-form-group label="فلتر" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="md">
                <b-form-input id="filterInput" v-model="title" type="search" placeholder="ابحث عن .." />
                <b-input-group-append>
                  <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
                </b-input-group-append>
                <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="gS()"
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اعادة تحميل</span>
              </b-button>
            </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>
        <b-row style="margin:10px">
                  <b-col md="4">
          <b-form-group>
            <v-select
              v-model="search.department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="departments"
              placeholder="اختر القسم"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <v-select
              v-model="search.stage_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="stages"
              placeholder="اختر المرحلة"
            />
          </b-form-group>
        </b-col>
                </b-row>
                <b-row style="margin-right:10px;margin-bottom:10px">
                  <b-col md="3">
                    <b-form-checkbox-group
              id="checkbox-group-2"
              name="flavour-2"
              class="demo-inline-spacing"
              v-model="new_req"
            >
              <b-form-checkbox :value="true"> عرض طلبات قيد الانجاز </b-form-checkbox>
            </b-form-checkbox-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-checkbox-group
              id="checkbox-group-2"
              name="flavour-2"
              class="demo-inline-spacing"
              v-model="req_dep_done"
            >
              <b-form-checkbox :value="true"> عرض طلبات الموافق عليها من الاقسام المعنية </b-form-checkbox>
            </b-form-checkbox-group>

                  </b-col>
                  <b-col md="3">
                    <b-form-checkbox-group
              id="checkbox-group-2"
              name="flavour-2"
              class="demo-inline-spacing"
              v-model="review"
            >
              <b-form-checkbox :value="true"> عرض الطلبات التي لم اوافق عليها او ارفضها </b-form-checkbox>
            </b-form-checkbox-group>

                  </b-col>

                </b-row>
        <div class="demo-vertical-spacing">
          <b-progress v-if="precentage !== 0" :value="precentage" max="100" />
        </div>
        <b-table ref="table" striped hover responsive class="position-relative" :per-page="perPage" :items="items"
          :fields="fields">
          <template #cell(users)="data">
            <b-badge v-for="stage in data.item" :key="stage.id" pill :variant="stage.pivot.is_confirm">
              {{ stage.full_name }}
            </b-badge>
          </template>
          <template #cell(email)="data">
              <row>
                <b-col md="12" style="margin-bottom: 10px;">
                  <a v-if="data.item.email!=null&&data.item.email!=`null`" :href="'mailto:' + data.item.email"
                        >{{data.item.email}}</a
                      >

                </b-col>
                <b-col md="12">
                  <b-button v-if="data.item.phone"
              @click="sendMessage(data.item.phone,data.item.title)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
              >
              <feather-icon
              icon="MessageSquareIcon"
              class="mr-50"
              />
                <span class="align-middle">واتساب</span> 
              </b-button>
                </b-col>
              </row>
            </template>
          <template #cell(date)="data">
            <p>{{ shortenDate(data.item.created_at) }}</p>
          </template>
          <template #cell(image)="file">
            <b-button v-if="file.item.image !== null" v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-info"
              @click="onClicked(file.item.image)">
              عرض
            </b-button>
            <b-button v-else v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-danger" disabled>
              didn't uploaded
            </b-button>
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.is_done == 1" pill :variant="success">
              تم العمل
            </b-badge>
            <b-badge v-else pill :variant="waiting">
              قيد العمل
            </b-badge>
            <div v-if="role && !data.item.is_done">
              <b-badge  v-if="data.item.forworded==1"
              pill
              :variant="success"
            >
              تم التحويل
            </b-badge>
            <b-badge v-else
              pill
              :variant="waiting"
            >
              طلب جديد
            </b-badge>

            </div>
            <div v-if="!data.item.is_done">
              <b-badge v-if="data.item.sts_reg==0"
              pill
              :variant="danger"
            >
              التسجيل
            </b-badge>
              <b-badge v-else-if="data.item.sts_reg==2"
              pill
              :variant="success"
            >
            التسجيل
            </b-badge>
            <b-badge v-else
              pill
              :variant="waiting"
            >
            التسجيل
            </b-badge>
              <b-badge v-if="data.item.sts_fin==0"
              pill
              :variant="danger"
            >
              الحسابات
            </b-badge>
              <b-badge v-else-if="data.item.sts_fin==2"
              pill
              :variant="success"
            >
            الحسابات
            </b-badge>
            <b-badge v-else
              pill
              :variant="waiting"
            >
            الحسابات
            </b-badge>
              <b-badge v-if="data.item.sts_dep==0"
              pill
              :variant="danger"
            >
              القسم العلمي
            </b-badge>
              <b-badge v-else-if="data.item.sts_dep==2"
              pill
              :variant="success"
            >
            القسم العلمي
            </b-badge>
            <b-badge v-else
              pill
              :variant="waiting"
            >
            القسم العلمي
            </b-badge>

            </div>
          </template>
          <template #cell(payment)="file">
            <b-button v-if="file.item.payment !== null" v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-info"
              @click="onClicked(file.item.payment)">
              عرض
            </b-button>
            <b-button v-else v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-danger" disabled>
              didn't uploaded
            </b-button>
          </template>
          <template #cell(action)="action">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-col md="3">
                <b-button v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-success" @click="showForm(action.item)">
                  سير الكتاب والاستمارة
                </b-button>
              </b-col>
              <b-col md="3" v-if="role">
                <b-button v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-primary" @click="fEM(action.item)"
                  v-b-modal.model-f-w>
                  تحويل
                </b-button>
              </b-col>
              <b-col md="3" v-if="role">
                <b-button v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-success"
                  @click="finsh(action.item.id)">
                  تم
                </b-button>
              </b-col>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" @change="check()" size="sm" inline
              :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @change="check()">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
        <b-modal id="model-e-w" ok-title="حفظ التعديلات" centered size="lg" title="تعديل كتاب رسمي" @ok="uM"
          @hidden="rEF">
          <b-form novalidate class="needs-validation">
            <b-form-row>
              <b-col md="12">
                <b-form-group>
                  <label for="basic-password">المرسل لهم</label>
                  <v-select v-model="editform.users" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="full_name"
                    multiple :options="users" placeholder="اختر المنتسبين" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="basic-password">نوع الكتاب</label>
                  <v-select v-model="editform.type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="ar_title" :options="types" placeholder="اختر نوع الكتاب" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="basic-password">النوع الفرعي</label>
                  <v-select v-model="editform.subtype_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="ar_title" :options="subtypes" placeholder="اختر النوع الفرعي للكتاب(ان وجد)" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label for="input-valid1">الموضوع :</label>
                <b-form-input id="input-valid1" v-model="editform.title" :state="editform.title.length > 0"
                  placeholder="الموضوع" />
              </b-col>

              <b-col md="6">
                <label for="input-invalid1">العدد:</label>
                <b-form-input id="input-invalid1" v-model="editform.number" placeholder="العدد" />
              </b-col>
              <b-col md="6">
                <b-form-group label="تاريخ الكتاب" label-for="date">
                  <cleave id="date" v-model="editform.date" class="form-control" :raw="false" :options="date"
                    placeholder="YYYY-MM-DD" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label for="input-invalid1">تفاصبل الكتاب:</label>
                <b-form-textarea id="input-invalid1" placeholder="تفاصبل الكتاب" v-model="editform.description"
                  rows="3" />
              </b-col>
              <b-col md="12">
                <label for="input-valid1">اختر ملف الكتاب (pdf.):</label>
                <b-form-file placeholder="اختر الصورة او اسقطها هنا..." drop-placeholder="اختر الصورة او اسقطها هنا..."
                  v-model="editform.file" @change="selectFileForForm" />
              </b-col>
            </b-form-row>
          </b-form>
        </b-modal>
        <b-modal id="model-r-w" ok-title="حفظ البيانات" centered :ok-disabled="confirmed || replyform.message.length<6" size="lg" title="الرد على الكتاب" @ok="rDM"
          @hidden="rRM">
          <b-form novalidate class="needs-validation">
            <div class="demo-inline-spacing" v-if="confirmed">
                <h4>هل توافق على قبول الطلب ؟</h4>
                <b-button variant="relief-success" @click="confirmRe(2)">
                قبول الطلب
              </b-button>
              <b-button variant="relief-danger" @click="confirmRe(0)">
                رفض الطلب
              </b-button>
            </div>
            <b-form-row>
              <b-col md="12">
                <label for="input-invalid1">الرسالة او التوجيه:</label>
                <b-form-textarea id="input-invalid1" placeholder="الرسالة او التوجيه" v-model="replyform.message"
                  rows="3" />
              </b-col>
              <b-col md="12">
                <label for="input-valid1">اختر ملف الكتاب (pdf.):</label>
                <b-form-file placeholder="اختر الصورة او اسقطها هنا..." drop-placeholder="اختر الصورة او اسقطها هنا..."
                  v-model="replyform.file" @change="selectFileForForm" />
              </b-col>
            </b-form-row>
          </b-form>
        </b-modal>
        <b-modal id="model-f-w" ok-title="حفظ البيانات" centered :ok-disabled="confirmed || forwardform.message.length<6" size="lg" title="تحويل طلب" @ok="fM" @hidden="rF">
          <b-form novalidate class="needs-validation">
            <b-form-row>
              <div class="demo-inline-spacing" v-if="confirmed">
                   <h4>هل توافق على قبول الطلب ؟</h4>
              <b-button variant="relief-success" @click="confirm(2)">
                قبول الطلب
              </b-button>
              <b-button variant="relief-danger" @click="confirm(0)">
                رفض الطلب
              </b-button>
            </div>
              <b-col md="12">
                <b-form-group>
                  <label for="basic-password">موجه الى</label>
                  <v-select v-model="forwardform.users" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="full_name" multiple :options="users" placeholder="اختر المنتسبين" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label for="input-invalid1">الرسالة او التوجيه:</label>
                <b-form-textarea id="input-invalid1" placeholder="الرسالة او التوجيه" v-model="forwardform.message"
                  rows="3" />
              </b-col>
            </b-form-row>
          </b-form>
        </b-modal>
        <b-modal id="modal-center" centered title="حذف الكتاب" @ok="dM" ok-title="حذف الحقل">
          <b-card-text>
            هل تريد حذف هذه الحقل "
            {{ this.editform.ar_name }} " ?
          </b-card-text>
        </b-modal>
      </b-card-code>
    </b-col>
  </b-row>
</template>
  
<script>
import vSelect from "vue-select";
import { filesUrl } from "@/main.js";
import store from "@/store";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
  BTable,
  BAvatar,
  BModal, BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  BProgress,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BRow,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardBody,
  BIconFileEarmarkSlides,
  BImg,
  BMedia,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { SID, TID, AID } from "@/main.js";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import emailjs from "@emailjs/browser";
import { rmdir } from "fs";
export default {
  components: {
    BCardCode,
    BTable,
    BForm, BCard,
    BCardActions,
    vSelect,
    BAvatar,
    BProgress,
    BFormFile,
    Cleave,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    BFormCheckboxGroup,
    BFormCheckbox,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormTextarea,
    BFormInvalidFeedback,
    BImg,
    BMedia,
    BCollapse,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    VBTooltip,
  },
  data() {
    return {
      confirmed:true,
      documentform: false,
      new_req:false,
      req_dep_done:false,
        review:"",
      precentage: 0,
      teacher_id: "",
      perPage: 10,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      role: null,
      canEdit: null,
      canDelete: null,
      currentPage: 0,
      documentRepliesid: false,
      documentreplyID: null,
      department: "",
      title: "",
      replies: [], success: 'success',
      waiting: 'warning',
      danger: 'danger',
      iD: "",
      item: "",
      id: "",
      filter: null,
      filterOn: [],
      departments: [],
      stages: [],
      users: [],
      search: {
        department_id:null,
        stage_id:null,
        types: [],
        subtypes: [],
        type_id: "",
        subtype_id: "",
        fromdate: "",
        todate: "",
      },
      fields: [
        {
          key: "title",
          label: "الاسم الرباعي",
        },
        {
          key: "department.ar_name",
          label: "القسم",
        },
        {
          key: "email",
          label: "ايميل",
        },
        {
          key: "status",
          label: "حالة الطلب",
        },
        {
          key: "image",
          label: "صورة شخصية",
        },
        {
          key: "payment",
          label: "الرسوم",
        },
        {
          key: "date",
          label: "تاريخ الطلب",
        },
        { key: "action", label: "الاجراءات" },
      ],
      exportFields: {
        Title: "ar_name",
        Stage: "stage.ar_name",
        Syllabus: "celbas",
        Department: "department.ar_name",
        Quntity: "files_count",
        Lecturer: "teacher.ar_name",
      },
      exportData: [
        {
          TotalName: "full_name",
          Stage: "stage.ar_name",
          Syllabus: "celbas",
          Department: "department.ar_name",
          Quntity: "files_count",
          Lecturer: "teacher.ar_name",
        },
      ],
      items: [],
      involved: [],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        user_id: "",
        teacher_id: "",
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      form: {
        title: "",
        department_id: "",
        stage_id: "",
        type_id: "",
        to: "",
        phone: "",
        file: "",
        email: "",
      },
      editform: {
        title: "",
        department_id: "",
        stage_id: "",
        type_id: "",
        to: "",
        phone: "",
        file: "",
        id: null,
      },
      cform: {
        title: null,
        depart: null,
        depart_id: null,
        stage: null,
        stage_id: null,
        blood: null,
        type_id: null,
        address: null,
        phone: null,
        file: null,
        payment: null,
        email: null
      },
      forwardform: {
        message: "",
        users: [],
        card_id: null,
      },
      replyform: {
        message: "",
        file: null,
        users: [],
        card_id: null,
      },
      date: {
        date: true,
        delimiter: "-",
        datePattern: ["Y", "m", "d"],
      },
      documentMenitions: [],

      currentUser: null,
      toEmail: false,
      toName: false,
      subject: null,
      toID: null,
      m: null,
      toUserID: null,
      types: [],
      subtypes: [],
    };
  },
  watch: {
    title: function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
    "$route.params.document_id": {
      handler: function (search) {
        this.gS();
      },
    },
    "search.type_id": function (val) {
      if (val != null || val != "") {
        this.gS();
      }
    },
    "search.fromdate": function (val) {
      if (val != null || val != "") {
        this.gS();
      }
    },
    "search.stage_id": function (val) {
      if (val != null || val != "") {
        this.gS();
      }
    },
    "search.department_id": function (val) {
      if (val != null || val != "") {
        this.gM2()
        this.gS()
      }
    },
    review: function (val) {
        console.log(val)
        if(val){
          if(localStorage.getItem("NisourRole") == "coordinator"){
          this.review = "sts_dep"
        }else if(localStorage.getItem("NisourRole") == "registration"){
          this.review = "sts_reg"
        }else if(localStorage.getItem("NisourRole") == "financial"){
          this.review = "sts_fin"
        }
        }else{
          this.review=null
        }
      },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    if (localStorage.getItem("NisourRole") == "registration") {
      this.role = true;
    } else {
      this.role = false;
    }
    if (
      localStorage.getItem("NisourRole") == "admin" ||
      localStorage.getItem("NisourRole") == "coordinator"
    ) {
      this.canEdit = true;
      if (localStorage.getItem("NisourRole") == "coordinator") {
        this.iD = localStorage.getItem("NisourDepartmentId");
      }
    } else {
      this.canEdit = false;
    }

    if (localStorage.getItem("NisourRole") == "admin") {
      this.canDelete = true;
    } else {
      this.canDelete = false;
      this.search.department = localStorage.getItem("NisourDepartmentId");
    }
    this.currentUser = localStorage.getItem("NisourUserId");
    this.gS(), this.gU(), this.gTD(), this.gSTD(),this.gM1();
  },
  methods: {
    async gM1(){
      await this.axios
        .get(`since/deparment?take=100&skip=${
            this.currentPage - 1
          }&select=true&type=5`)
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gM2(){
      this.stages = []
      this.search.stage_id==""
      console.log(this.search.department_id)
      if(! Number.isInteger(this.search.department_id)){
        this.search.department_id = this.search.department_id["id"]
      }
      await this.axios
        .get(`stage/clint/department/${this.search.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gTD() {
      await this.axios
        .get(`types?hr=true`)
        .then((res) => {
          this.types = [];
          this.types = res.data.items;
          this.search.types = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSTD() {
      await this.axios
        .get(`subtypes`)
        .then((res) => {
          this.subtypes = [];
          this.subtypes = res.data.items;
          this.search.subtypes = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },

    async gU() {
      await this.axios
        .get(`users-name`)
        .then((res) => {
          this.users = [];
          this.users = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    sI(item) {
      this.cform.title = item.title
      this.cform.blood = item.blood
      this.cform.depart = item.department.ar_name
      this.cform.stage = item.stage.ar_name
      this.cform.type_id = item.type.ar_title
      this.cform.address = item.address
      this.cform.phone = item.phone
      this.cform.file = item.file
      this.cform.payment = item.payment
      this.cform.email = item.email
      this.documentform = true
    },
    async finsh(id) {
      await this.axios
        .get(`card/finsh/${id}`)
        .then((res) => {
          this.finalMessage(res.data.success.phone,res.data.success.title)
          this.gS()
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rRM()
    },
    async forwarded(id) {
        await this.axios
          .get(`card/${id}/forwarded`)
          .then((res) => {
            this.gS();
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
    async uM() {
      let data = new FormData();
      data.append("title", this.editform.title);
      data.append("depart", this.editform.depart);
      data.append("stage", this.editform.stage);
      data.append("email", this.editform.email);
      data.append("file", this.editform.file);

      await this.axios
        .post(`emails/${this.editform.id}`, data, {
          onUploadProgress: (progressEvent) => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          },
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            if (this.editform.users.length > 0) {
              for (let i = 0; i < this.editform.users.length; i++) {
                (this.toEmail = this.editform.users[i].email),
                  (this.toName = this.editform.users[i].full_name),
                  (this.toID = this.editform.users[i].teacher_id),
                  (this.toUserID = this.editform.users[i].id),
                  (this.subject = this.editform.title),
                  (this.m = `سيد ${this.toName
                    } : نود اعلامك بورود تحقق من ${localStorage.getItem(
                      "NisourUserName"
                    )}لغرض عمل حساب جامعي. يرجى فتح الحساب و تأكيد الاستلام `);
                this.sendEmail(this.m, this.toName, this.toEmail);
                this.notifi.route = "em-notif";
                this.notifi.title = "طلب تحقق وارد ";
                this.notifi.item_id = res.data.id;
                this.notifi.table = "email_reqs";
                this.notifi.user_id = this.toUserID;
                this.notifi.type = "light-success";
                this.notifi.subtitle = ` ورد اليك طلب تحقق `;
                this.notifi.bg = "bg-success";
                this.sendNoti();
              }
            }

            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.precentage = 0;
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async fM() {
      let id = this.forwardform.card_id
      const ids = [];
      if (this.forwardform.users.length > 0) {
        this.forwardform.users.map(function (value, key) {
          ids.push(value.id);
        });
      }
      let data = new FormData();
      data.append("card_id", this.forwardform.card_id);
      data.append("message", this.forwardform.message);
      ids.forEach((element) => data.append("users[]", element));
      await this.axios
        .post(`card/forward`, data, {
          onUploadProgress: (progressEvent) => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          },
        })
        .then((res) => {
        
          this.scrollToElement()
          this.forwarded(id)
          
          this.pM(id)
          if (res.data.errors) {
            this.errorToast();
          } else {
            if (this.forwardform.users.length > 0) {

              for (let i = 0; i < this.forwardform.users.length; i++) {
                (this.toEmail = this.forwardform.users[i].email),
                  (this.toName = this.forwardform.users[i].full_name),
                  (this.toID = this.forwardform.users[i].teacher_id),
                  (this.toUserID = this.forwardform.users[i].id),
                  (this.subject = this.forwardform.users.message),
                  (this.m = `سيد ${this.toName
                    } : نود اعلامك بانه قدر تم تحويل كتاب رسمي اليك من ${localStorage.getItem(
                      "NisourUserName"
                    )} يرجى فتح الحساب والاطلاع `);
                this.sendEmail(this.m, this.toName, this.toEmail);
                this.notifi.route = "ca-notif";
                this.notifi.title = "طلب محول ";
                this.notifi.item_id = this.forwardform.card_id;
                this.notifi.table = "reptocas";
                this.notifi.user_id = this.toUserID;
                this.notifi.type = "light-success";
                this.notifi.subtitle = `طلب محول اليك`;
                this.notifi.bg = "bg-success";
                this.sendNoti();
              }
            }

            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.precentage = 0;
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rFM()
    },

    rFM() {
      this.message = "",
        this.card_id = null
    },
    async rDM() {
      if (isNaN(this.replyform.card_id)) {
        this.replyform.card_id = this.replyform.card_id["id"];
      }
      let data = new FormData();
      data.append("message", this.replyform.message);
      data.append("card_id", this.replyform.card_id);
      data.append("file", this.replyform.file);
      await this.axios
        .post(`card/reply`, data, {
          onUploadProgress: (progressEvent) => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          },
        })
        .then((res) => {
          this.scrollToElement()
          this.pM(res.data.card_id)
          if (res.data.errors) {
            this.errorToast();
          } else {
            if (this.involved.length > 0) {
              for (let i = 0; i < this.involved.length; i++) {
                if (!Number.isInteger(this.involved[i].id) & (this.involved[i].id != "")) {
                  this.involved[i] = this.involved[i].user;
                }
                if (this.involved[i].id != this.currentUser) {

                  (this.toEmail = this.involved[i].email),
                    (this.toName = this.involved[i].full_name),
                    (this.toID = this.involved[i].teacher_id),
                    (this.toUserID = this.involved[i].id),
                    (this.subject = this.replyform.message),
                    (this.m = `سيد ${this.toName
                      } : نود اعلامك بانه قدر تم الردعلى طلب هوية من ${localStorage.getItem(
                        "NisourUserName"
                      )} الخاص برقم الطلب ${res.data.card_id} يرجى فتح الحساب و الاطلاع`);
                  this.sendEmail(this.m, this.toName, this.toEmail);
                  this.notifi.route = "ca-notif";
                  this.notifi.title = "الردود ";
                  this.notifi.item_id = this.replyform.card_id;
                  this.notifi.table = "reptocas";
                  this.notifi.user_id = this.toUserID;
                  this.notifi.type = "light-success";
                  this.notifi.subtitle = ` ورد اليك رد جديد `;
                  this.notifi.bg = "bg-success";
                  this.sendNoti();
                }
              }
            }
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.precentage = 0;
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rRM()
    },
    async confirm(status){
        await this.axios
          .get(`card/confirmed/${this.forwardform.card_id}?status=${status}`)
          .then((res) => {
            this.gS();
            this.confirmed = false
            if(status==2){
              this.forwardform.message = "تم الموافقة على الطلب و صحة البيانات"
            }else{
              this.forwardform.message = "لم تتم الموافقة على الطلب و ذلك للاسباب التالية :"
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });

      },
      async confirmRe(status){
        await this.axios
          .get(`card/confirmed/${this.replyform.card_id.id}?status=${status}`)
          .then((res) => {
            this.gS();
            this.confirmed = false
            if(status==2){
              this.replyform.message = "تم الموافقة على الطلب و صحة البيانات"
            }else{
              this.replyform.message = "لم تتم الموافقة على الطلب و ذلك للاسباب التالية :"
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });

      },
    showForm(item){
      this.pM(item)
      this.sI(item)
      this.confirmed = true
    },
    rRM() {
      this.replyform.message = "",
        this.replyform.file = null,
        this.documentMenitions = [],
        this.card_id = null
    },
    sendMessage(phoneNumber,name) {
      const message = `عزيزي ${name} نود اعلامك ان طلب الهوية الجامعية قد تم رفضه .%0A و ذلك بسبب : %0A%0Aيرجى ارسال طلب اخر مع معالجة الملاحظات المذكورة.%0Aعلما هذه مسج صادر من النظام و لا حاجة للرد على هذه المسج.`;
      const whatsappWebURL = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
      window.open(whatsappWebURL);
    },
    finalMessage(phoneNumber,name) {
      const message = `عزيزي ${name} نود اعلامك ان طلب الهوية الجامعية قد تم انجازه.%0A يرجى مراجعة قسم التسجيل لاستلام الهوية الجامعية.%0Aعلما هذه مسج صادر من النظام و لا حاجة للرد على هذه المسج.`;
      const whatsappWebURL = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
      window.open(whatsappWebURL);
    },
    sendEmail(m, n, e) {
      const fromName = localStorage.getItem("NisourUserName");
      const fromEmail = localStorage.getItem("NisourEmail");
      emailjs
        .send(
          SID,
          TID,
          {
            from_name: fromName,
            to_name: n,
            message: m,
            to_email: e,
            reply_to: fromEmail,
          },
          AID
        )
        .then(
          (result) => {
            this.makeToast(
              "success",
              "Notification Sender",
              `The notification has been sent successfully`
            );
            this.toName = "";
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
    async aNM() {
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id["id"];
      }
      if (isNaN(this.form.subtype_id)) {
        this.form.subtype_id = this.form.subtype_id["id"];
      }
      const ids = [];
      if (this.form.users.length > 0) {
        this.form.users.map(function (value, key) {
          ids.push(value.id);
        });
      }
      let data = new FormData();
      data.append("title", this.form.title);
      data.append("number", this.form.number);
      data.append("type_id", this.form.type_id);
      data.append("date", this.form.date);
      data.append("subtype_id", this.form.subtype_id);
      data.append("description", this.form.description);
      data.append("file", this.form.file);
      ids.forEach((element) => data.append("users[]", element));
      await this.axios
        .post(`documents`, data, {
          onUploadProgress: (progressEvent) => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          },
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            if (this.form.users.length > 0) {
              for (let i = 0; i < this.form.users.length; i++) {
                (this.toEmail = this.form.users[i].email),
                  (this.toName = this.form.users[i].full_name),
                  (this.toID = this.form.users[i].teacher_id),
                  (this.toUserID = this.form.users[i].id),
                  (this.subject = this.form.title),
                  (this.m = `سيد ${this.toName} : نود اعلامك بورود كتاب رسمي من ${this.fromName} يرجى فتح الحساب و تأكيد الاستلام `);
                this.sendEmail(this.m, this.toName, this.toEmail);
                this.notifi.route = "documents-notif";
                this.notifi.title = "كتاب رسمي وارد ";
                this.notifi.item_id = res.data.id;
                this.notifi.table = "documents";
                this.notifi.user_id = this.toUserID;
                this.notifi.type = "light-success";
                this.notifi.subtitle = `ورد اليك كتاب رسمي من قبل ${this.fromName}`;
                this.notifi.bg = "bg-success";
                this.sendNoti();
              }
            }
            this.gS();
          }
          this.rF();
          this.precentage = 0;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    rF() {
      (this.cform.title = ""),
        (this.cform.depart = ""),
        (this.cform.stage = ""),
        (this.cform.blood = ""),
        (this.cform.type_id = ""),
        (this.cform.address = ""),
        (this.cform.phone = ""),
        (this.cform.email = []),
          this.confirmed = true,
        (this.cform.file = "");
    },
    scrollToElement() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    },
    rEF() {
      (this.editform.title = ""),
        (this.editform.numbe = ""),
        (this.editform.date = ""),
        (this.editform.type_id = ""),
        (this.editform.subtype_id = ""),
        (this.editform.description = ""),
        (this.documentMenitions = []),
        (this.editform.users = []),
        (this.editform.file = "");
    },
    async dM() {
      await this.axios
        .delete(`documents/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
            this.notifi.route = "lectures-notif";
            this.notifi.title = "delete action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "lectures";
            this.notifi.role_id = 1;
            this.notifi.type = "light-danger";
            this.notifi.subtitle = "deleted lecture";
            this.notifi.bg = "bg-danger";
            this.sendNoti();
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      (this.editform.title = item.title),
        (this.editform.number = item.number),
        (this.editform.date = item.date);
      this.editform.type_id = item.type_id;
      this.editform.subtype_id = item.subtype_id;
      this.editform.description = item.description;
      this.editform.file = item.file;
      this.editform.users = item.users;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    // ID() {
    //   this.iD = localStorage.getItem("NisourUserId");
    // },
    hideform() {
      this.documentform = false;
      this.rF()
    },
    async gS() {
      let stage=null
        if(! Number.isInteger(this.search.stage_id )&&this.search.stage_id!=null&&this.search.stage_id!=""){
        stage = this.search.stage_id["id"]
      }else{
        stage=null
      }
      await this.axios
        .get(
          `card?take=${this.perPage}&skip=${this.currentPage - 1}&new_req=${this.new_req}&req_dep_done=${this.req_dep_done}&stage_id=${stage}
            &title=${this.title}&request_id=${this.$route.params.id}&department_id=${this.iD}&review=${this.review}&bydepartment_id=${this.search.department_id}
            `
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    selectFileForForm(e) {
      this.form.file = "";
      this.form.file = e.target.files[0];
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      this.editform.file = e.target.files[0];
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""),
        (this.search.department_id = ""),
        (this.search.stage_id = ""),
        (this.search.fromdate = ""),
        (this.search.todate = "");
      this.gS();
    },
    onClicked(path) {
      window.open(`${filesUrl}/${path}`, "_blank");
    },
    sE(email) {
      return `mailto:${email.email}`;
    },
    async cM(item) {
      await this.axios
        .get(`documents/${item.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async pM(item) {
      this.scrollToElement()
      if (isNaN(item)) {
        this.replyform.card_id = item.id;
      }else{
        this.replyform.card_id = item
      }
      await this.axios
        .get(`card/replies?card_id=${this.replyform.card_id}`)
        .then((res) => {
          this.replies = res.data.items;
          this.involved = res.data.users;
          if(item.sts_reg!=1&&localStorage.getItem("NisourRole") == "registration"){
              this.confirmed = false;
            }
            if(item.sts_fin!=1&&localStorage.getItem("NisourRole") == "financial"){
              this.confirmed = false;
            }
            if(item.sts_dep!=1&&localStorage.getItem("NisourRole") == "coordinator"){
              this.confirmed = false;
            }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.documentRepliesid = false
      this.replyform.card_id = item;
      this.documentRepliesid = true
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    mentions(mentions) {
      let items = []
      mentions.forEach((element) =>
        items += `${element.full_name} , `)
      return items;
    },
    fEM(item) {
      this.forwardform.card_id = item.id;
      this.forwardform.users = item.users
      this.documentMenitions = item.users
      this.confirmed = true;
      if (this.documentMenitions) {
        this.documentMenitions[this.documentMenitions.length] = item.user
      }
      if(item.sts_reg!=1&&localStorage.getItem("NisourRole") == "registration"){
          this.confirmed = false;
        }
        if(item.sts_fin!=1&&localStorage.getItem("NisourRole") == "financial"){
          this.confirmed = false;
        }
        if(item.sts_dep!=1&&localStorage.getItem("NisourRole") == "coordinator"){
          this.confirmed = false;
        }
    },
    rR(item) {

      this.replyform.card_id = item.id
      this.replyform.users = item.users
      if (this.replyform.users) {
        this.replyform.users[this.replyform.users.length] = item.user
      }
    },
    hideCard() {
      this.documentRepliesid = false;
      this.confirmed = true
        this.rF()
    },
  },
  setup() {
    const printProgress = () => {
      window.print()
    }

    return {
      printProgress,
    }
  }
};
</script>
<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .table {
    display: none !important;
  }

  .demo-inline-spacing {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
  